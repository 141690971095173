import HardwareTable from '@/components/Hardware/HardwareTable';
import ServerStatus from '@/components/Hardware/ServerStatus';
import ServiceStatus from '@/components/Hardware/ServiceStatusTable';
import DocViewer from '@/components/General/DocViewer';
import './SystemTab.scss';
import { defineComponent } from 'vue';
import useHardware from '@/compositions/useHardware';
import { useToast } from 'vue-toastification';
import { Card, Button, Selector, Alert, ModalBox } from '@/components/UI';
import Reset from '@/views/Reset/Reset.vue';
export default defineComponent({
    name: 'SystemTab',
    components: {
        HardwareTable,
        DocViewer,
        ServerStatus,
        ServiceStatus,
        Card,
        Button,
        Selector,
        Alert,
        ModalBox,
        Reset
    },
    setup: () => ({
        ...useHardware(),
        toast: useToast()
    }),
    data: () => ({
        filter: 'online',
        restartVisible: false,
        searchingForUpdates: false,
        showResetBox: false,
        filters: [
            { text: 'Online only', value: 'online' },
            { text: 'All', value: 'all' }
        ]
    }),
    methods: {
        async restartall() {
            this.restartVisible = false;
            try {
                await this.$store.direct.dispatch.hardware.restartAll();
                this.toast.success(`All pis are restarting.`);
            }
            catch (error) {
                this.toast.error(`Error Restarting Pis, ${error}`);
            }
        },
        async searchForUpdates() {
            this.searchingForUpdates = true;
            await this.$store.direct.dispatch.software.updatePackages();
            setTimeout(() => this.searchingForUpdates = false, 15000);
        }
    }
});
